exports.components = {
  "component---packages-gatsby-theme-jqi-src-pages-404-tsx": () => import("./../../../../../packages/gatsby-theme-jqi/src/pages/404.tsx" /* webpackChunkName: "component---packages-gatsby-theme-jqi-src-pages-404-tsx" */),
  "component---packages-gatsby-theme-jqi-src-pages-search-tsx": () => import("./../../../../../packages/gatsby-theme-jqi/src/pages/search.tsx" /* webpackChunkName: "component---packages-gatsby-theme-jqi-src-pages-search-tsx" */),
  "component---packages-gatsby-theme-jqi-src-templates-article-tsx": () => import("./../../../../../packages/gatsby-theme-jqi/src/templates/Article.tsx" /* webpackChunkName: "component---packages-gatsby-theme-jqi-src-templates-article-tsx" */),
  "component---packages-gatsby-theme-jqi-src-templates-basic-page-tsx": () => import("./../../../../../packages/gatsby-theme-jqi/src/templates/BasicPage.tsx" /* webpackChunkName: "component---packages-gatsby-theme-jqi-src-templates-basic-page-tsx" */),
  "component---packages-gatsby-theme-jqi-src-templates-person-tsx": () => import("./../../../../../packages/gatsby-theme-jqi/src/templates/Person.tsx" /* webpackChunkName: "component---packages-gatsby-theme-jqi-src-templates-person-tsx" */),
  "component---packages-gatsby-theme-jqi-src-templates-publication-tsx": () => import("./../../../../../packages/gatsby-theme-jqi/src/templates/Publication.tsx" /* webpackChunkName: "component---packages-gatsby-theme-jqi-src-templates-publication-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-people-tsx": () => import("./../../../src/pages/people.tsx" /* webpackChunkName: "component---src-pages-people-tsx" */)
}

